<template>
  <v-bottom-sheet
    v-model="detail_deposit"
    inset
    max-width="600px"
  >
    <v-sheet
      class="rounded-t-xl pb-10"
    >
      <v-container :ref="'detail'+'1'">
        <v-toolbar
          dense
          flat
          color="transparent"
        >
          <v-btn
            icon
            @click="detail_deposit = false"
          >
            <v-icon>
              mdi-chevron-left
            </v-icon>
          </v-btn>
          <v-spacer />
          <v-toolbar-title>{{ $t('xiangqing') }}</v-toolbar-title>
          <v-spacer />
        </v-toolbar>

        <v-list
          class="py-0 px-3"
        >
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>
                {{ $t('tijiao_shijian') }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-list-item-subtitle>
                {{ detail.created }}
              </v-list-item-subtitle>
            </v-list-item-action>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>
                {{ $t('dingdanbianhao') }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-list-item-subtitle>
                {{ detail.orderno }}
                <v-btn
                  small
                  icon
                  @click="doCopy(detail.orderno, 'detail1')"
                >
                  <v-icon small>
                    mdi-content-copy
                  </v-icon>
                </v-btn>
              </v-list-item-subtitle>
            </v-list-item-action>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>
                {{ $t('pay_jine') }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-list-item-subtitle>
                <strong>{{ detail.remark_money }}</strong> {{ $store.state.agentinfo.currency }}
              </v-list-item-subtitle>
            </v-list-item-action>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>
                {{ $t('shuliang') }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-list-item-subtitle>
                + {{ detail.amount }} {{ $t('fen') }}
              </v-list-item-subtitle>
            </v-list-item-action>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>
                {{ $t('zhuangtai') }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-list-item-subtitle>
                <v-chip
                  small
                  color="green lighten-5"
                  text-color="green"
                >
                  {{ $t('yiwancheng') }}
                </v-chip>
              </v-list-item-subtitle>
            </v-list-item-action>
          </v-list-item>
          <!--
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>
                {{ $t('pay_usdt_chain') }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-list-item-subtitle>
                TRX (TRC20)
              </v-list-item-subtitle>
            </v-list-item-action>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>
                TxID
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-list-item-subtitle>
                <a
                  class="text-decoration-none"
                  href="#"
                  target="_blank"
                >
                  {{ detail.remark }}
                </a>
                <v-btn
                  small
                  icon
                  @click="doCopy(detail.remark, 'detail1')"
                >
                  <v-icon small>
                    mdi-content-copy
                  </v-icon>
                </v-btn>
              </v-list-item-subtitle>
            </v-list-item-action>
          </v-list-item>
          -->
        </v-list>
      </v-container>
    </v-sheet>
  </v-bottom-sheet>
</template>

<script>
export default {
  name: 'DetailDeposit',
  props: {
    detail: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    detail_deposit: true,
  }),
  computed: {

  },
  watch:{
    detail_deposit: {
      handler(newVal, oldVal) {
        console.log(newVal, oldVal)
        if(newVal==false){
          this.$emit('close_detaildeposit')
        }
      }
    },
  },
  created() {
    
  },
  mounted() {
    
  },
  methods: {
    doCopy: function (text, ref) {
      text = ''+text//转string
      this.$copyText(text, this.$refs[`${ref}`]).then( (e)=>{
        console.log(e)
        this.$snackbar.info(this.$t('copy_ok'))
      }, function (e) {
        console.log(e)
      })
    },
  },
};
</script>