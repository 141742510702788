<template>
  <v-bottom-sheet
    v-model="detail_send"
    inset
    max-width="600px"
  >
    <v-sheet
      class="rounded-t-xl pb-10"
    >
      <v-toolbar
        :ref="'detail6'"
        dense
        flat
        color="transparent"
      >
        <v-btn
          icon
          @click="detail_send = false"
        >
          <v-icon>
            mdi-chevron-left
          </v-icon>
        </v-btn>
        <v-spacer />
        <v-toolbar-title>{{ $t('xiangqing') }}</v-toolbar-title>
        <v-spacer />
      </v-toolbar>

      <v-list
        class="py-0 px-3"
      >
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>
              {{ $t('tijiao_shijian') }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-list-item-subtitle>
              {{ detail.created }}
            </v-list-item-subtitle>
          </v-list-item-action>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>
              {{ $t('dingdanbianhao') }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-list-item-subtitle>
              {{ detail.orderno }}
              <v-btn
                small
                icon
                @click="doCopy(detail.orderno, 'detail6')"
              >
                <v-icon small>
                  mdi-content-copy
                </v-icon>
              </v-btn>
            </v-list-item-subtitle>
          </v-list-item-action>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>
              {{ detail.to_role=='agent'?$t('daili'):$t('wanjia') }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-list-item-subtitle>
              <v-icon small>
                mdi-account
              </v-icon>
              {{ detail.member_name }}
              <v-icon small>
                mdi-chevron-right
              </v-icon>
            </v-list-item-subtitle>
          </v-list-item-action>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>
              {{ $t('shuliang') }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-list-item-subtitle>
              -{{ detail.amount }}
            </v-list-item-subtitle>
          </v-list-item-action>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>
              {{ $t('zhuangtai') }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-list-item-subtitle>
              <v-chip
                v-if="detail.status==0"
                small
                color="lighten-5"
              >
                {{ $t('chulizhong') }}
              </v-chip>
              <v-chip
                v-if="detail.status==1"
                small
                color="green lighten-5"
                text-color="green"
              >
                {{ $t('yiwancheng') }}
              </v-chip>
              <v-chip
                v-if="detail.status==-1"
                small
                color="red lighten-5"
                text-color="red"
              >
                {{ $t('shibai') }}
              </v-chip>
            </v-list-item-subtitle>
          </v-list-item-action>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>
              {{ $t('beizhu_jine') }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-list-item-subtitle>
              {{ detail.remark_money }}
            </v-list-item-subtitle>
          </v-list-item-action>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>
              {{ $t('beizhu_neirong') }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-list-item-subtitle>
              {{ detail.remark }}
            </v-list-item-subtitle>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-sheet>
  </v-bottom-sheet>
</template>

<script>
export default {
  name: 'DetailSend',
  props: {
    detail: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    detail_send: true,
  }),
  computed: {

  },
  watch:{
    detail_send: {
      handler(newVal, oldVal) {
        console.log(newVal, oldVal)
        if(newVal==false){
          this.$emit('close_detailsend')
        }
      }
    },
  },
  created() {
    
  },
  mounted() {
    
  },
  methods: {
    doCopy: function (text, ref) {
      text = ''+text//转string
      this.$copyText(text, this.$refs[`${ref}`]).then( (e)=>{
        console.log(e)
        this.$snackbar.info(this.$t('copy_ok'))
      }, function (e) {
        console.log(e)
      })
    },
  },
};
</script>